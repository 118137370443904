import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';

export function useViewBreakpoints() {
    const breakpoints = useBreakpoints(breakpointsTailwind);

    const smallerThanLg = breakpoints.smaller('lg');
    const smallerThanMd = breakpoints.smaller('md');
    const smallerThanSm = breakpoints.smaller('sm');

    const greaterThanLg = breakpoints.greater('lg');
    const smallerGreaterSm = breakpoints.greater('sm');

    return {
        breakpoints,
        smallerThanLg,
        greaterThanLg,
        smallerGreaterSm,
        smallerThanMd,
        smallerThanSm,
    };
}
